<template>
  <div class="wrapper">
    <van-tabs v-model:active="currentTab" @change="onClickTab" swipeable sticky color="#2ac560">
        <van-tab title="发起的拼单">
            <div class="swiper-cnt">
              <van-list v-if="groupList.length > 0"
              v-model:loading="loading"
              :finished="finished"
              :immediate-check = "false"
              finished-text="没有更多了"
              @load="pageLoad"
            >
              <div class="mygroup-list" v-for="item in groupList" :key="item.id">
                  <div class="mygroup-title">
                    <img class="l" src="../../images/order_company@2x.png" />
                    <span class="l">{{item.shopName}}</span>
                    <!--  //0：组团中1：成功2：失败  -->
                    <p :class="{'group-status-success':item.purchaseStatus==='3','group-status-fail':item.purchaseStatus==='2','group-status-ing':item.purchaseStatus==='0'}">
                      <span v-if="item.joinType === '2' && item.joinStatus === '1'" class="r">已支付</span>
                      <span v-if="item.joinType === '2' && item.joinStatus === '0'" class="r">未支付</span>
                      <span v-if="item.purchaseStatus==='3'" class="r">发起拼单-</span>
                      <span v-if="item.purchaseStatus==='2'" class="r">拼单失败-</span>
                      <span v-if="item.purchaseStatus==='1'" class="r">拼单成功-</span>
                      <span v-if="item.purchaseStatus==='0'" class="r">进行中-</span>
                    </p>
                    <div class="clear"></div>
                  </div>
                  <router-link :to='`/groupdetail/${item.makeGroupUserId}`'>
                    <div class="mygroup-info">
                      <div class="mygroup-img">
                        <img :src="item.goodsFileUrl?item.goodsFileUrl:require('../../images/default_goods.png')" />
                      </div>
                      <div class="mygroup-info-r">
                        <h4>{{item.goodsName}} <span class="private_domain" v-if="item.groupType==1">私域拼团</span></h4>
                        <div class="mygroup-info-list">
                          <span v-if="item.purchaseType==0">{{item.joinNum}}人团 </span>
                          <span v-if="item.purchaseType==0">共{{item.totalNum+item.unit}}</span>
                          <span v-if="item.purchaseType==0"> 每人{{item.perNum+item.unit}}</span>
                          <span v-if="item.purchaseType==1">{{item.totalNum+item.unit}}团</span>
                        </div>
                        <div class="mygroup-info-list">
                          <span class="price-new">¥{{item.goodsPrice}}/{{item.unit}}</span>
                        </div>
                      </div>
                      <div class="clear"></div>
                    </div>
                  </router-link>
                  <div class="mygroup-bom">
                    <img v-for="iteminner in item.userList" :key="iteminner.id" :src="iteminner" />
                    <img v-if="item.userList.length ===0 " src="../../images/default_avatar.png" />
                    <span class="mygroup-num">{{item.joinStr}}</span>
                    <router-link :to='`/groupdetail/${item.makeGroupUserId}`'>
                    <a href="javascript:;" class="mygroup-detail r"
                      >查看详情</a></router-link>
                    <a href="javascript:;" v-if="isToBuy(item)" @click="goPay(item)" class="mygroup-detail r">付款</a>
                  </div>
              </div>
            </van-list>
          </div>
          <ContentNo v-if="groupList.length <=0" :contentTips="contentTips"/>
        </van-tab>
        <van-tab title="参与的拼单">
            <div class="swiper-cnt">
              <van-list v-if="groupList.length > 0"
              v-model:loading="loading"
              :finished="finished"
              :immediate-check = "false"
              finished-text="没有更多了"
              @load="pageLoad"
            >
              <div class="mygroup-list" v-for="item in groupList" :key="item.id">
                  <div class="mygroup-title">
                    <img class="l" src="../../images/order_company@2x.png" />
                    <span class="l">{{item.shopName}}</span>
                    <!--  //0：组团中1：成功2：失败  -->
                    <p :class="{'group-status-success':item.purchaseStatus==='3','group-status-fail':item.purchaseStatus==='2','group-status-ing':item.purchaseStatus==='0'}">
                      <span v-if="item.joinType === '2' && item.joinStatus === '1'" class="r">已支付</span>
                      <span v-if="item.joinType === '2' && item.joinStatus === '0'" class="r">未支付</span>
                      <span v-if="item.purchaseStatus==='3'" class="r">发起拼单-</span>
                      <span v-if="item.purchaseStatus==='2'" class="r">拼单失败-</span>
                      <span v-if="item.purchaseStatus==='1'" class="r">拼单成功-</span>
                      <span v-if="item.purchaseStatus==='0'" class="r">进行中-</span>
                    </p>
                    <div class="clear"></div>
                  </div>
                  <router-link :to='`/groupdetail/${item.makeGroupUserId}`'>
                    <div class="mygroup-info">
                      <div class="mygroup-img">
                        <img :src="item.goodsFileUrl?item.goodsFileUrl:require('../../images/default_goods.png')" />
                      </div>
                      <div class="mygroup-info-r">
                        <h4>{{item.goodsName}} <span class="private_domain" v-if="item.groupType==1">私域拼团</span></h4>
                        <div class="mygroup-info-list">
                          <span v-if="item.purchaseType==0">{{item.joinNum}}人团 </span>
                          <span v-if="item.purchaseType==0">共{{item.totalNum+item.unit}}</span>
                          <span v-if="item.purchaseType==0"> 每人{{item.perNum+item.unit}}</span>
                          <span v-if="item.purchaseType==1">{{item.totalNum+item.unit}}团</span>
                        </div>
                        <div class="mygroup-info-list">
                          <span class="price-new">¥{{item.goodsPrice}}/{{item.unit}}</span>
                        </div>
                      </div>
                      <div class="clear"></div>
                    </div>
                  </router-link>
                  <div class="mygroup-bom">
                    <img v-for="iteminner in item.userList" :key="iteminner.id" :src="iteminner" />
                    <img v-if="item.userList.length ===0 " src="../../images/default_avatar.png" />
                    <span class="mygroup-num">{{item.joinStr}}</span>
                    <router-link :to='`/groupdetail/${item.makeGroupUserId}`'>
                    <a href="javascript:;" class="mygroup-detail r"
                      >查看详情</a></router-link>
                    <a href="javascript:;" v-if="isToBuy(item)" @click="goPay(item)" class="mygroup-detail r">付款</a>
                  </div>
              </div>
            </van-list>
            <ContentNo v-if="groupList.length <=0" :contentTips="contentTips"/>
          </div>
        </van-tab>
    </van-tabs>
  </div>
  <Tabbar :carNumNew="carNumNew"/>
</template>
<script>
import qs from 'qs'
import { post, ajaxParam } from '../../units/request.js'
import Tabbar from '../home/Tabbar.vue'
import ContentNo from '../../components/ContentNo.vue'
export default {
  name: 'Mygroup',
  components: {
    Tabbar, ContentNo
  },
  data () {
    return {
      groupList: [],
      currentTab: 0,
      isLastPage: 0,
      pageNum: 1,
      pageSize: 6,
      carNumNew: 0,
      finished: false,
      loading: false,
      contentTips: '暂无拼团订单'
    }
  },
  methods: {
    async getGroup () {
      const url = 'v1/groupPurchase/getetOriginateGroupOrderInfoList.json'
      const param = {
        customerUserType: this.currentTab,
        customerUserId: localStorage.userId,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      ajaxParam(param)
      const res = await post(url, qs.stringify(param))
      this.groupList.push(...res.data.list)
      this.isLastPage = res.data.isLastPage
      if (this.isLastPage === 1) {
        this.finished = true
      }
      // 获取完数据把Loading状态改成false
      this.loading = false
    },
    // 分页
    pageLoad () {
      this.loading = true
      this.pageNum++
      this.getGroup()
    },
    // 切换
    onClickTab (e) {
      this.currentTab = e
      this.groupList = []
      this.pageNum = 1
      this.loading = false
      this.finished = false
      this.isLastPage = 0
      this.getGroup()
    },
    goPay (item) {
      const paySn = item.orderSn
      this.$store.commit('changeCar', item)
      this.$router.push({
        path: '/paytype',
        query: {
          paySn: paySn
        }
      })
    }
  },
  created () {
    this.carNumNew = localStorage.carNumNew
    this.getGroup()
  },
  computed: {
    isToBuy () {
      return function (item) {
        // purchaseStatus 0：组团中1：成功2：失败 3发起拼单
        // joinType 1货到付款2在线支付
        // joinStatus 加入状态（0否1是）
        return item.joinType === '2' && (item.purchaseStatus === '0' || item.purchaseStatus === '3') && item.joinStatus === '0' && Date.now() <= item.makeGroupEndTime
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.l {
  float: left;
}
.r {
  float: right;
}
.clear {
  clear: both;
}
.wrapper {
  background: #f3f3f3;
  padding-bottom: .5rem;
}

.mygroup-list {
  background: #fff;
  margin-top: 0.1rem;
  padding: 0 0.16rem .05rem;
  position: relative;
  font-size: .13rem;
}
.mygroup-title {
  height: 0.4rem;
  line-height: 0.4rem;
  border-bottom: 1px solid #f2f2f2;
}
.mygroup-title img {
  width: 0.2rem;
  height: 0.2rem;
  margin: 0.1rem 0.1rem 0 0;
}
.mygroup-info {
  margin: 0.1rem 0;
  position: relative;
}
.mygroup-img {
  float: left;
  width: 0.7rem;
  height: 0.7rem;
  margin-right: 0.15rem;
}
.mygroup-img img {
  width: 100%;
  height: 100%;
}
.mygroup-info-r {
  overflow: hidden;
}
.mygroup-info-r h4 {
  margin-top: 0.05rem;
  margin-bottom: 0;
}
.private_domain{
  padding:0 .03rem;
  background-color: red;
  font-size: .13rem;
  color:white !important;
  font-weight:bold;
  line-height: .2rem;
}
.mygroup-info-list {
  margin-top: 0.1rem;
}
.mygroup-bom {
  height: 0.4rem;
  padding-left: 0.1rem;
  line-height: 0.4rem;
  color: #2ac560;
}
.mygroup-bom img {
  width: 0.32rem;
  height: 0.32rem;
  border-radius: 50%;
  float: left;
  margin-left: -0.1rem;
}
.mygroup-num {
  margin-left: 0.05rem;
  font-size: 0.18rem;
}
.mygroup-detail {
  display: inline-block;
  margin-top: .06rem;
  margin-left: .2rem;
  height: 0.22rem;
  padding: 0 .06rem;
  border: 1px solid #2ac560;
  color: #2ac560;
  text-align: center;
  line-height: 0.22rem;
  border-radius: 0.04rem;
  font-size: 0.13rem;
  text-decoration: none;
}
.group-status-fail{color:#ed8779}
.group-status-success{color:#f78d04}
.group-status-ing{color:#55d180}
.more{
  text-align: center;
}
.price-new{
  color:#ff371e
}
</style>
